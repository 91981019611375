import React from "react";

// We'll use ethers to interact with the Ethereum network and our contract
import { ethers } from "ethers";
import bcrypt from 'bcryptjs';

// We import the contract's artifacts and address here, as we are going to be
// using them with ethers
import BVTest721Artifact from "../contracts/BVTest721.json";
import BVTest1155Artifact from "../contracts/BVTest1155.json";
import ERC721Artifact from "../contracts/ERC721.json";
import BitVillainsArtifact from "../contracts/BitVillains.json";
import contractAddresses from "../contracts/addresses.json";
import deploymentInput from "../deployment_input.json";

// All the logic of this dapp is contained in the Dapp component.
// These other components are just presentational ones: they don't have any
// logic. They just render HTML.
import { HelperNFT } from "./HelperNFT";
import { Login } from "./Login";
import { Villain } from "./Villain";
import { Recruitment } from "./Recruitment.js";
import { Events } from "./Events.js";
import { BondingCurve } from "./BondingCurve.js";
import { MainMenu } from "./MainMenu.js";
import AttributePage from "./AttributePage";
import { WalletStatus } from "./WalletStatus";
import { Villains } from "./Villains";
import {update_cache, short_addr} from "../helper.js";
import { ToastContainer, toast } from 'react-toastify';
import ReactModal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
import './Dapp.css';

import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";

const PASSWORD = "$2a$10$eRHp8Jx2EoWK5x4BUFB2iOBWijwXObljzA9ZyZoUutnFCfzl.2Ai."
// This is the Hardhat Network id, you might change it in the hardhat.config.js
// Here's a list of network ids https://docs.metamask.io/guide/ethereum-provider.html#properties
// to use when deploying to other networks.
// const HARDHAT_NETWORK_ID = '31337';
// const ROPSTEN_NETWORK_ID = '3';
const MILLISECONDS_IN_SECOND = 1000;

// This is an error code that indicates that the user canceled a transaction
const ERROR_CODE_TX_REJECTED_BY_USER = 4001;
const CHAIN_ID_MAP = {1: 'Mainnet', 3: 'Ropsten'}
const CURRENT_CHAIN_ID = 3;

// This component is in charge of doing these things:
//   1. It connects to the user's wallet
//   2. Initializes ethers and the Token contract
//   3. Polls the user balance to keep it updated.
//   4. Transfers tokens by sending transactions
//   5. Renders the whole application
//
// Note that (3) and (4) are specific of this sample application, but they show
// you how to keep your Dapp and contract's state in sync,  and how to send a
// transaction.

const DYNAMIC_CACHE_VARIABLES = ['existingVillains', 'userVillains', 'attrData', 'pricing', 'cachedBlock'];
function linkFormatter(cell, row, rowIndex){
    return <a rel="noreferrer" target="_blank" href={"https://ropsten.etherscan.io/tx/"+row['txHash']}>{short_addr(row['txHash'])}</a>
}
const VALHALLA_COLUMNS = [
    {'dataField': 'txHash', 'text': 'Tx Hash', 'formatter':linkFormatter},
    {'dataField': 'ownerShort', 'text': 'Owner', style: {}, },
    {'dataField': 'id', 'text': 'BV Id'},
    {'dataField': 'priceReceived', 'text': 'Valhalla Price'},
    {'dataField': 'nextRecruitPrice', 'text': 'Next Recruit Price'},
    {'dataField': 'newValhallaPrice', 'text': 'New Valhalla Price'},
    {'dataField': 'membersAlive', 'text': 'Members Alive'},
    {'dataField': 'reserve', 'text': 'BV Reserve'}];
const RECRUITS_COLUMNS = [
    {'dataField': 'txHash', 'text': 'Tx Hash', 'formatter':linkFormatter},
    {'dataField': 'toShort', 'text': 'Owner', style: {'width':'5px'}},
    {'dataField': 'id', 'text': 'BV Id'},
    {'dataField': 'pricePaid', 'text': 'Price Paid'},
    {'dataField': 'nextRecruitmentPrice', 'text': 'Next Recruitment Price'},
    {'dataField': 'newValhallaPrice', 'text': 'New Valhalla Price'},
    {'dataField': 'membersAlive', 'text': 'Members Alive'},
    {'dataField': 'reserve', 'text': 'BV Reserve'}];
const TRIBULATIONS_COLUMNS = [
    {'dataField': 'txHash', 'text': 'Tx Hash', 'formatter':linkFormatter},
    {'dataField': 'whoShort', 'text': 'Owner', style: {'width':'5px'}},
    {'dataField': 'id', 'text': 'BV Id'},
    {'dataField': 'result', 'text': 'Result'},
    {'dataField': 'numPunished', 'text': 'Times Punished'},
    {'dataField': 'numBlessed', 'text': 'Times Blessed'}];
const HELPERMINTS_COLUMNS = [
    {'dataField': 'to', 'text': 'Owner', style: {'width':'5px'}},
    {'dataField': 'tokenId', 'text': 'Token Id'}];
let attributesToCategories = {};
let categoriesToAttributes = {};
let attrDeployData = [];
let attrNameToData = {}
for (const categoryName of Object.keys(deploymentInput)) {
    categoriesToAttributes[categoryName] = [];
    for (const attr of deploymentInput[categoryName]) {
        attributesToCategories[attr['name']] = categoryName;
        categoriesToAttributes[categoryName].push(attr['name']);
        attrNameToData[attr['name']] = attr;
        attrDeployData.push(attr);
    }
}


const EXPECTED_LENGTH_HEX_NUM_IN_ATTR_POOL = 3;
const EXPECTED_LENGTH_HEX_NUM_ATTR = 2;

export class Dapp extends React.Component {
  constructor(props) {
    super(props);

    if(localStorage.getItem('contractAddress') !== contractAddresses['BitVillains']){
        localStorage.clear();
        localStorage.setItem('contractAddress', contractAddresses['BitVillains']);
    }

    let isAuthenticated = false;
    const storedPwd = localStorage.getItem("cachedPassword");
    if (storedPwd && bcrypt.compareSync(storedPwd, PASSWORD)){
        isAuthenticated = true;
    }
    // We store multiple things in Dapp's state.
    // You don't need to follow this pattern, but it's an useful example.
    this.initialState = {
      // The info of the token (i.e. It's Name and symbol)
      tokenData: undefined,
      // The user's address and balance
      selectedAddress: undefined,
      balance: undefined,
      // The ID about transactions being sent, and any possible error with them
      txBeingSent: undefined,
      transactionError: undefined,
      networkError: undefined,
      networkReady : false,
      onCorrectNetwork: false,
      currentNetwork: undefined,
      canvasRendered: false,
      existingVillains:  [],
      userVillains: {},
      pixelData: undefined,
      showModal: false, 
      events: {
          valhallas: {'rows': [], 'columns': VALHALLA_COLUMNS},
          recruits: {'rows': [], 'columns': RECRUITS_COLUMNS},
          tribulations: {'rows': [], 'columns': TRIBULATIONS_COLUMNS},
          helperMints: {'rows': [], 'columns': HELPERMINTS_COLUMNS}
      },
      statusData: {},
      authenticated: isAuthenticated,
      curves: {
          recruit : [],
          valhalla : []
      }
    };
    // cachedBlock is last time in current session block was updated (metadata)
    // cachedBlockNumber is the actual value set in the dynamic data array
    this.state = this.initialState;
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal () {
    this.setState({ showModal: true });
  }

  handleCloseModal () {
    this.setState({ showModal: false });
  }

  componentDidMount() {
    document.body.style.background = 'linear-gradient(0deg, rgba(0,128,128,1) 0%, rgba(0,32,128,1) 100%) fixed';
    // We reset the dapp state if the network is changed
    if (window.ethereum){
        window.ethereum.on("chainChanged", ([networkId]) => {
          window.location.reload();
        });
        this._provider = new ethers.providers.Web3Provider(window.ethereum);
        this._asyncInit();
    }
    else{
        this.setState({showModal:true})
    }
  }

  _authenticate(pwd){
    /*var salt = bcrypt.genSaltSync(10);
    var hash = bcrypt.hashSync(pwd, salt);
    */
    if (bcrypt.compareSync(pwd, PASSWORD)){
        this.setState({authenticated:true});
        localStorage.setItem("cachedPassword", pwd);
    }
  }

  render() {
    // Ethereum wallets inject the window.ethereum object. If it hasn't been
    // injected, we instruct the user to install MetaMask.
    if(!this.state.authenticated){
      return <Login
        loginFunc={(pwd) =>
          this._authenticate(pwd)
        }
      />;
    }

    // If the token data or the user's balance hasn't loaded yet, we show
    // a loading component.

    // If everything is loaded, we render the application.
    const fontStyle = {fontFamily:'PressStart2P', fontSize:'.8em'};
    return (
      <Router basename="" hashType="noslash">
      <div style={fontStyle}>
        <MainMenu
          address={this.state.selectedAddress}
        />
        <div id="colorBar" />
        <div style={{}}>
        <WalletStatus 
          statusData={this.state.statusData}
          hasProvider={this._provider ? true : false}
          onCorrectNetwork={this.state.onCorrectNetwork}
          networkReady={this.state.networkReady}
          connectWallet={() => this._connectWallet()}
        />
        </div>
        <br />

      <Switch>
      <Route path="/attributePool">
        <AttributePage pixelData={this.state.pixelData} attrData={this.state.attrData} />
      </Route>
      <Route path="/bitVillains">
      <Villains isUserVillains={false}pixelData={this.state.pixelData} villains={this.state.existingVillains} />
      </Route>
      <Route path="/plunder">
      <Villains pixelData={this.state.pixelData} villains={this.state.existingVillains}  isPlunder={true}/>
      </Route>
      <Route path="/userBitVillains">
      <Villains isUserVillains={true} pixelData={this.state.pixelData} villains={this.state.userVillains[this.state.selectedAddress]?? []}/>
      </Route>
      <Route path='/helper'>
          <HelperNFT
            awardItem={(address, metadata) =>
              this._awardItem(address, metadata)
            }
            address={this.state.selectedAddress}
            data={this.state.events.helperMints}
            hasProvider={this._provider ? true : false}
            onCorrectNetwork={this.state.onCorrectNetwork}
            networkReady={this.state.networkReady}
          />
      </Route>
      <Route path='/bondingCurve'>
          <BondingCurve
            villains={this.state.existingVillains}
            curves={this.state.curves}
          />
      </Route>
      <Route path='/recruitment'>
          <Recruitment
            recruitMember={(sAddr, sId) =>
              this._recruitMember(sAddr, sId)
            }
            isApproved={(nftContractAddr, sId) =>
              this._isApproved(nftContractAddr, sId)
            }
            getApproval={(sAddr, sId) =>
              this._getApproval(sAddr, sId)
            }
            pricing={this.state.pricing}
            defaultValue={contractAddresses['BVTest721']}
            hasProvider={this._provider ? true : false}
            onCorrectNetwork={this.state.onCorrectNetwork}
            networkReady={this.state.networkReady}
          />
      </Route>
      <Route exact path="/">
        <Redirect to="/index" />
      </Route>
      <Route path="/index">
          <div className='MainBodyElement' style={{width:'80%', padding:"1%"}}>
        Unique digital art collectibles with every bit stored immutably on the Ethereum blockchain. No third party image hosting or unstable IPFS binning needed. These are BitVillains.
        <br /><br />
        The recruitment price of a BitVillain is determined by the <Link to="/bondingCurve">Bonding Curve</Link>, meaning a higher number of BitVillains at large means a higher recruitment price. Recruiting a BitVillain also requires a contribution of <Link to="/plunder">Plunder</Link>: an NFT- any ERC721 token- irrevocably given to the BitVillain contract. The Plunder NFT will be forever associated with the corresponding BitVillain.
        <br /><br />
        A newly recruited BitVillain is randomly assigned attributes from the <Link to="/attributePool">Attribute Pool</Link>. BitVillains, their attributes, and their associated Plunder can all be viewed in the BitVillain’s details in the <Link to="/bitVillains">Rogues' Gallery</Link> as well as other NFT galleries.
        <br /><br />
        BitVillain holders have two interaction options: Valhalla or Tribulation.
        <ul>
        <li>Sending a BitVillain to Valhalla permanently burns a BitVillain in exchange for a reward. The BitVillain’s Attributes are returned to the Attribute Pool and the holder is rewarded with 80% of the most recently paid recruitment price.</li>
        <li>Facing a Tribulation results in two outcomes with equal likelihood. The BitVillain could be Blessed and have its pixel image lightened by 1/16. Alternatively, the BitVillain could be Punished and have its pixel image darkened by 1/16. Tribulations cost 50% of the current Valhalla reward.</li>
        </ul>
        Some people just want to watch NFTs burn. <Link to="/recruitment">Recruit a BitVillain now.</Link>
          </div>
      </Route>
      <Route path="/events">
        <Events 
            data={this.state.events}
        />
      </Route>
      <Route path="/villain/:vId" children={<Villain villainData={this.state.existingVillains} pixelData={this.state.pixelData} userAddress={this.state.selectedAddress} categories={Object.keys(categoriesToAttributes)}
            tribulation={(memberId) =>
              this._tribulation(memberId)
            }
            valhalla={(memberId, minAmt) =>
              this._valhalla(memberId, minAmt)
            }
            pricing={this.state.pricing} />} />
      <Route path='*'><div className="MainBodyElement" style={{width:"10%", textAlign:'center'}}><h1>404</h1>Are you lost?</div></Route>
      </Switch>
      </div>
      <ToastContainer position="bottom-right" />
      <ReactModal
         isOpen={this.state.showModal}
         contentLabel="onRequestClose Example"
         onRequestClose={this.handleCloseModal}
         className="Modal"
         overlayClassName="Overlay"
      >
        <div style={fontStyle}>
        <h2>Warning! You do not have Web3 provider installed</h2>
        <p>The BitVillains frontend runs entirely clientside- without a Web3 provider connected to the Ropsten network, all blockchain functionality is disabled. Please install <a href="http://metamask.io" target="_blank" rel="noopener noreferrer">MetaMask</a> to properly access the BitVillains dApp.</p>
        <button onClick={this.handleCloseModal}>Ok</button>
        </div>
      </ReactModal>
      </Router>
    );
  }

  componentWillUnmount() {
  }

  makeContract(addr, contractArtifact){
    return new ethers.Contract(
      addr,
      contractArtifact.abi,
      this._provider.getSigner(0)
    );
}

  async _asyncInit() {
    // first check the network
    if (!(await this._checkNetwork())) {
        return;
    }
    const accountList = await this._provider.listAccounts();
    // if account list is empty, we need to prompt the user to connect
    if (accountList.length > 0){
        await this._connectWallet()
    }
}

  async _connectWallet() {
    const cachedVals = {};
    for (const varName of DYNAMIC_CACHE_VARIABLES){
        const cachedVal = JSON.parse(localStorage.getItem(varName));  
        if (cachedVal)
            cachedVals[varName] = cachedVal;
    }
    const statusData = this.state.statusData;
    statusData.cachedBlock = cachedVals.cachedBlock;
    cachedVals['statusData'] = statusData;
    this.setState({...cachedVals});

    if(!this.state.pixelData){
    const cachedPixelData = JSON.parse(localStorage.getItem("pixelData"));
    if(cachedPixelData && Object.keys(cachedPixelData) !== 0){
        this.setState({pixelData:cachedPixelData});
    }
    else{
        this._getPixelData().then((pixelData)=>{;
          localStorage.setItem("pixelData", JSON.stringify(pixelData));
          this.setState({pixelData});
        });
    }
    }
    let selectedAddress;
    try {
        [selectedAddress] = await window.ethereum.request({ method: 'eth_requestAccounts' });
    }
    catch (error) {
        console.log("error eth_requestAccounts", error);
        if (error.message === "User rejected the request.")
            toast.error("Connection to MetaMask was rejected, please try again!");
        return;
    }
    this.setState({networkReady : true})
    this._helperContracts = this._getHelperContracts();
    this._setupContractData();
    this._bvContract = this.makeContract(contractAddresses['BitVillains'], BitVillainsArtifact);
    this._initialize(selectedAddress);
    // We first initialize ethers by creating a provider using window.ethereum
    this._provider.on("pending", (tx) =>{
        console.log('pending');
        console.log(tx);
    });
    this._provider.on("block", (blockNumber) => {
        this.setState(prevState => update_cache('currentBlock', blockNumber, prevState));
    })
    // TODO this should be cleared up beacuse of the copy paste code. we should parse events in one function somewhow
    this._helperContracts['erc721'].on("Transfer", (from, to, tokenId) =>{
        if (ethers.constants.AddressZero === from){
            tokenId = parseInt(tokenId);
            console.log('erc71 sample mint: ', to, tokenId);
            let helperMints = this.state.events.helperMints;
            helperMints['rows'].push({to, tokenId});
            this.setState({helperMints});
        }
    })
    this._bvContract.on("MemberRecruited", (to, id, pricePaid, nextRecruitmentPrice, newValhallaPrice, membersAlive, reserve, data) => {
        const eventKey = `${to} ${id} ${pricePaid} ${nextRecruitmentPrice} ${newValhallaPrice} ${membersAlive} ${reserve}`;
        let recruits = this.state.events.recruits;
        to = ethers.utils.getAddress(to);
        const toShort = short_addr(to);
        id = parseInt(id);
        pricePaid = ethers.utils.formatEther(parseInt(pricePaid).toString());
        nextRecruitmentPrice = ethers.utils.formatEther(parseInt(nextRecruitmentPrice).toString());
        newValhallaPrice = ethers.utils.formatEther(parseInt(newValhallaPrice).toString());
        membersAlive = parseInt(membersAlive);
        reserve = ethers.utils.formatEther(parseInt(reserve).toString());
        this._memberToRecruitedMap[id] = {'to': to, 'id': id, 'pricePaid': pricePaid, 'newValhallaPrice': newValhallaPrice};
        const txHash = data.transactionHash;
        recruits['rows'].push({to, id, pricePaid, nextRecruitmentPrice, newValhallaPrice, membersAlive, reserve, eventKey, toShort, txHash});
        this.setState({recruits});
    });
    this._bvContract.on("MemberToValhalla", (owner, id, priceReceived, nextRecruitPrice, newValhallaPrice, membersAlive, reserve, data) => {
        const eventKey = `${owner} ${id} ${priceReceived} ${nextRecruitPrice} ${newValhallaPrice} ${membersAlive} ${reserve}`;
        const ownerShort = short_addr(owner);
        id = parseInt(id);
        priceReceived = ethers.utils.formatEther(parseInt(priceReceived).toString());
        nextRecruitPrice = ethers.utils.formatEther(parseInt(nextRecruitPrice).toString());
        newValhallaPrice = ethers.utils.formatEther(parseInt(newValhallaPrice).toString());
        membersAlive = parseInt(membersAlive);
        reserve = ethers.utils.formatEther(parseInt(reserve).toString());
        let valhallas = this.state.events.valhallas;
        const txHash = data.transactionHash;
        valhallas['rows'].push({owner, id, priceReceived, nextRecruitPrice, newValhallaPrice, membersAlive, reserve, ownerShort, eventKey, txHash});
        this._memberToRecruitedMap[id] = {'to': 'valhalla'};
        this.setState({valhallas});
    });
    this._bvContract.on("Tribulation", (who, id, isPunishment, numPunished, numBlessed, data) => {
        const eventKey = `${who} ${id} ${isPunishment} ${numPunished} ${numBlessed}`;
        who = ethers.utils.getAddress(who);
        const whoShort = short_addr(who);
        id = parseInt(id);
        numPunished = parseInt(numPunished).toString();
        numBlessed = parseInt(numBlessed).toString();
        let tribulations = this.state.events.tribulations;
        const result = isPunishment ? "Punishment" : "Blessing";
        const txHash = data.transactionHash;
        tribulations['rows'].push({who, id, result, numPunished, numBlessed, eventKey, whoShort, txHash});
        this.setState({tribulations});
    });
    const curves = { ...this.state.curves };
    for(var numBVAlive=0;numBVAlive<1000;numBVAlive++){
        // INITIAL_RECRUIT_PRICE * (1 + curMembersAlive / RECRUIT_PRICE_INCREASE_RATE)
        const rp = .01 * (numBVAlive/1 + 1);
        const vp = Math.max(.01 * (numBVAlive) * 0.8, 0);
        curves.recruit.push({numBVAlive, rp});
        curves.valhalla.push({numBVAlive, vp});
    }
    this.setState({curves});
    this._updateDynamicData(selectedAddress);
    this._updateDynamicDataInterval = setInterval(() => this._updateDynamicData(selectedAddress), MILLISECONDS_IN_SECOND * 30);

    // We reinitialize it whenever the user changes their account.
    window.ethereum.on("accountsChanged", ([newAddress]) => {
      // `accountsChanged` event can be triggered with an undefined newAddress.
      // This happens when the user removes the Dapp from the "Connected
      // list of sites allowed access to your addresses" (Metamask > Settings > Connections)
      // To avoid errors, we reset the dapp state 
      if (newAddress === undefined) {
        return this._resetState();
      }
      
      this._initialize(newAddress);
    });
  }

  _initialize(userAddress) {
    // This method initializes the dapp

    // We first store the user's address in the component's state
    this.setState({
      selectedAddress: ethers.utils.getAddress(userAddress),
    });

    // Fetching the token data and the user's balance are specific to this
    // sample project, but you can reuse the same initialization pattern.
    this._intializeEthers();
  }

  async _intializeEthers() {
    this._memberToRecruitedMap = {}
    let events = {...this.state.events};
    events.recruits['rows'] = [];
    events.valhallas['rows'] = [];
    events.tribulations['rows'] = [];
    events.helperMints['rows'] = [];
    let eventResults = await this._helperContracts['erc721'].queryFilter(this._helperContracts['erc721'].filters.Transfer());
    for(const event of eventResults){
        let [from, to, tokenId] = event.args;
        if (ethers.constants.AddressZero === from){
            tokenId = parseInt(tokenId);
            events.helperMints['rows'].push({to, tokenId});
        }
    }
    eventResults = await this._bvContract.queryFilter(this._bvContract.filters.MemberRecruited());
    for(const event of eventResults){
        const eventKey = `${event.args}`;
        const txHash = event.transactionHash;
        let [to, id, pricePaid, nextRecruitmentPrice, newValhallaPrice, membersAlive, reserve] = event.args;
        to = ethers.utils.getAddress(to);
        const toShort = short_addr(to);
        id = parseInt(id);
        pricePaid = ethers.utils.formatEther(parseInt(pricePaid).toString());
        nextRecruitmentPrice = ethers.utils.formatEther(parseInt(nextRecruitmentPrice).toString());
        newValhallaPrice = ethers.utils.formatEther(parseInt(newValhallaPrice).toString());
        membersAlive = parseInt(membersAlive);
        reserve = ethers.utils.formatEther(parseInt(reserve).toString());
        this._memberToRecruitedMap[id] = {'to': to, 'id': id, 'pricePaid': pricePaid, 'newValhallaPrice': newValhallaPrice};
        events.recruits['rows'].push({to, id, pricePaid, nextRecruitmentPrice, newValhallaPrice, membersAlive, reserve, eventKey, toShort, txHash});
    }
    eventResults = await this._bvContract.queryFilter(this._bvContract.filters.MemberToValhalla());
    for(const event of eventResults){
        const eventKey = `${event.args}`;
        const txHash = event.transactionHash;
        let [owner, id, priceReceived, nextRecruitPrice, newValhallaPrice, membersAlive, reserve] = event.args;
        const ownerShort = short_addr(owner);
        id = parseInt(id);
        priceReceived = ethers.utils.formatEther(parseInt(priceReceived).toString());
        nextRecruitPrice = ethers.utils.formatEther(parseInt(nextRecruitPrice).toString());
        newValhallaPrice = ethers.utils.formatEther(parseInt(newValhallaPrice).toString());
        membersAlive = parseInt(membersAlive);
        reserve = ethers.utils.formatEther(parseInt(reserve).toString());
        events.valhallas['rows'].push({owner, id, priceReceived, nextRecruitPrice, newValhallaPrice, membersAlive, reserve, eventKey, ownerShort, txHash})
        this._memberToRecruitedMap[id] = {'to': 'valhalla'}
    }
    eventResults = await this._bvContract.queryFilter(this._bvContract.filters.Tribulation());
    for(const event of eventResults){
        const txHash = event.transactionHash;
        const eventKey = `${event.args}`;
        let [who, id, price, isPunishment, numPunished, numBlessed] = event.args;
        who = ethers.utils.getAddress(who);
        const whoShort = short_addr(who);
        id = parseInt(id);
        numPunished = parseInt(numPunished).toString();
        numBlessed = parseInt(numBlessed).toString();
        const result = isPunishment ? "Punishment" : "Blessing";
        events.tribulations['rows'].push({who, id, result, numPunished, numBlessed, eventKey, whoShort, txHash, price})
    }
    this.setState(events)

  }

  _getHelperContracts() {
    let helperContracts = {};
    helperContracts['erc721'] = this.makeContract(contractAddresses['BVTest721'], BVTest721Artifact);
    helperContracts['erc1155'] = this.makeContract(contractAddresses['BVTest1155'], BVTest1155Artifact);
    return helperContracts;
  }

  _setupContractData(){
    /*let attrContracts = {};
    for (var key of Object.keys(contractAddresses['Attribute'])) {
        let addr = contractAddresses['Attribute'][key];
        const contract = new ethers.Contract(
          addr,
          AttributeArtifact.abi,
          this._provider.getSigner(0)
        );
        attrContracts[key] = contract;
        attrContracts[addr] = contract;
    }
    this._attrContracts = attrContracts;
    let attrManContracts = {}
    for (var i of Object.keys(contractAddresses['AttributeManager'])) {
        let addr = contractAddresses['AttributeManager'][i];
        attrManContracts[i] = new ethers.Contract(
          addr,
          AttributeManagerArtifact.abi,
          this._provider.getSigner(0)
        );
    }
    this._attrManContracts = attrManContracts;*/
  }

  async _getPixelData() {
    let pixelData = {};
    for (const attr of attrDeployData) {
        if(!this.state.pixelData)
            pixelData[attr['name']] = {
                'pixels': attr['colors'],
                'useMap': attr['pixelMap']
            };
    }
    return pixelData;
  }

  parseManagerData(dataHex){
    const numAttrs = parseInt(dataHex.slice(-EXPECTED_LENGTH_HEX_NUM_ATTR), 16);
    const data = [];
    for(var i = 0;i < numAttrs; i++){
        const idx1 = -EXPECTED_LENGTH_HEX_NUM_ATTR - (i+1)*EXPECTED_LENGTH_HEX_NUM_IN_ATTR_POOL;
        const idx2 =  -EXPECTED_LENGTH_HEX_NUM_ATTR - (i)*EXPECTED_LENGTH_HEX_NUM_IN_ATTR_POOL;
        const numRemaining = parseInt(dataHex.slice(idx1, idx2), 16)
        data.push(numRemaining);
    }
    return data;
  }

  async _updateDynamicData(userAddress) {
    try{
        let data = {};
        data['attrData'] = {};
        var attrManIdx = 0;
        var attrIdx = 0;
        const attrIdxMap = {};
        for (const attrCategory of Object.keys(deploymentInput)) {
            const attrs = deploymentInput[attrCategory];
            let attrManData = await this._bvContract.m_attrMansStruct(attrManIdx);
            const attrDataAll = this.parseManagerData(attrManData.m_sizesNumRep['_hex']);
            let count = 0;
            for (const attrData of attrDataAll){
                let [attrName, attrCategory] = await this._bvContract.m_attrsStruct(attrIdx);
                attrIdxMap[attrIdx] = attrName;
                /*let addr = await this._attrManContracts[key].m_attrs(i);
                let size = await this._attrManContracts[key].m_sizes(i);
                let oSize = await this._attrManContracts[key].m_sizes_original(i);
                let contract = this._attrContracts[addr];
                let name = await contract.name();*/
                data['attrData'][attrName] = {};
                data['attrData'][attrName]['remainingGenePool'] = attrData;
                data['attrData'][attrName]['initialGenePool'] = attrs[count]['amount'];
                data['attrData'][attrName]['category'] = attrCategory;
                attrIdx ++;
                count ++;
            }
            attrManIdx ++;
        }
        data['existingVillains'] = [];
        data['userVillains'] = {};
        const numVillains = parseInt((await this._bvContract.getNumberMembersRecruited())['_hex']);
        var j = 0;
        for (j = 0; j < numVillains; j++){
            let attrs = await this._bvContract.getMemberAttributes(j);
            if (attrs.length < 1)
                break;
            let villain = {}
            villain['attrs'] = [];
            for (var k = 0; k < attrs.length; k++){
                const attrIdxTmp = parseInt(attrs[k]['_hex'], 16)
                villain['attrs'].push({'name': attrIdxMap[attrIdxTmp]});
            }
            let owner;
            try{
                // owner = await this._bvContract.ownerOf(j);
                owner = this._memberToRecruitedMap[j].to;
                // await this._bvContract.balanceOf(userAddress);
            } catch (error){
            }
            villain['owner'] = owner;
            const attrMap = await this._bvContract.m_attrMap(j);
            let [plunderAddr, plunderId] = await this._bvContract.m_sacrificeMap(j);
            plunderId = parseInt(plunderId);
            villain['numBlessed'] = attrMap.numBlessed;
            villain['numPunished'] = attrMap.numPunished;
            villain['id'] = j;
            villain['plunderAddr'] = plunderAddr;
            let metadata = '';
            if(ethers.constants.AddressZero !== plunderAddr){ 
                const nftContract =  this.makeContract(plunderAddr, ERC721Artifact);
                metadata = await nftContract.tokenURI(plunderId);
            }
            villain['plunderId'] = plunderId;
            villain['metadata'] = metadata;
            data['existingVillains'].push(villain);
            if(!(owner in data['userVillains']))
                data['userVillains'][owner] = [];
            data['userVillains'][owner].push(villain);
        }
        data['pricing'] = {};
        let vp = await this._bvContract.getValhallaPrice();
        let tp = await this._bvContract.getTribulatePrice();
        let rp = await this._bvContract.getRecruitPrice(parseInt((await this._bvContract.getNumberMembersAlive())['_hex']));
        // let tp = vp * .4;
        // let rp = await this._bvContract.getRecruitPrice(i);
        data['pricing']['valhalla'] = ethers.utils.formatEther(parseInt(vp['_hex']).toString());
        data['pricing']['tribulation'] = ethers.utils.formatEther(parseInt(tp['_hex']).toString());
        data['pricing']['recruit'] = ethers.utils.formatEther(parseInt(rp['_hex']).toString());
        data['cachedBlock'] = await this._provider.getBlockNumber();
        this.setState(prevState => update_cache('cachedBlock', data['cachedBlock'], prevState));

        this.setState(data);
        for (var l of Object.keys(data)){
            localStorage.setItem(l, JSON.stringify(data[l]));
        }
    } catch(error){
        console.log(error)
        console.log('caught error while updating dynamic data');
        if(false && !(error.code==="NETWORK_ERROR" && error.event === "changed"))
            await this._updateDynamicData(this.state.selectedAddress);
    }
  }

  async _isApproved(nftContractAddr, sId){
    try {
        const nftContract =  this.makeContract(nftContractAddr, ERC721Artifact);
        return await nftContract.getApproved(sId);
    } catch (error){
        return false;
    }
  }

  async _awardItem(to, metadata) {
    this._dismissTransactionError();
    await this._sendTx(this._helperContracts['erc721'].awardItem, to, metadata);
  }

  async _getApproval(sAddr, sId){
    this._dismissTransactionError();
    await this._sendTx(this._helperContracts['erc721'].approve, this._bvContract.address, sId);
  }

  async _recruitMember(sAddr, sId) {
    this._dismissTransactionError();
    let curRp = ethers.utils.parseEther("0.5");
    if(this.state.pricing.recruit)
      curRp = ethers.utils.parseEther(this.state.pricing.recruit);
    return await this._sendTx(this._bvContract.recruitMemberWith721, sAddr, sId, {value: curRp, gasLimit:800000});
  }

  async _tribulation(memberId) {

    this._dismissTransactionError();
    return await this._sendTx(this._bvContract.tribulate, memberId, {value:ethers.utils.parseEther(this.state.pricing['tribulation']), gasLimit:100000});
  }

  async _valhalla(memberId, minAmt) {
    this._dismissTransactionError();
    return await this._sendTx(this._bvContract.valhalla, memberId, minAmt);
  }

  async _sendTx(func, ...args){
    try {
      const tx = await func(...args);
      this.setState({ txBeingSent: tx.hash });
      toast.success("Transaction submitted! Follow its progress with your wallet");
      const receipt = await tx.wait();

      if (receipt.status === 0) {
        throw new Error("Transaction failed");
      }
      toast.success("Transaction mined! Block Number: " + receipt.blockNumber);
      await this._updateDynamicData(this.state.selectedAddress);

    } catch (error) {
      console.log(error);
      if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
        toast.error("Transaction was defined rejected by your wallet, please try again.")
      }
      else if (error.code === -32603) {
        toast.error("Transaction failed due to initial gas issue");
      }
      else if (error.code === "CALL_EXCEPTION") {
        toast.error("Transaction failed due to call exception, please check your gas limit and try again");
      }
      else if (error.error.code === -32603) {
        toast.error(error.error.message)
      }

      this.setState({ transactionError: error });
    } finally {
      this.setState({ txBeingSent: undefined });
    }
  }

  // This method just clears part of the state.
  _dismissTransactionError() {
    this.setState({ transactionError: undefined });
  }

  // This method just clears part of the state.
  _dismissNetworkError() {
    this.setState({ networkError: undefined });
  }

  // This is an utility method that turns an RPC error into a human readable
  // message.
  _getRpcErrorMessage(error) {
    if (error.data) {
      return error.data.message;
    }

    return error.message;
  }

  // This method resets the state
  _resetState() {
    this.setState(this.initialState);
  }

  // This method checks if Metamask selected network is Localhost:8545 
  async _checkNetwork() {
    let ret = false;
    let networkId = (await this._provider.getNetwork()).chainId;
    if ( networkId === CURRENT_CHAIN_ID) {
      ret = true;
      this._dismissNetworkError();
    }
    this.setState({ 
      networkId: networkId,
      onCorrectNetwork: ret,
      currentNetwork: CHAIN_ID_MAP[networkId]
    });
    if(!ret){
        this.setState({ 
          networkError: 'Please connect Metamask to Hardhat or Ropsten network'
        });
    }

    return ret;
  }
}
