import React, {useState} from "react";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import {ButtonGroup, ToggleButton} from 'react-bootstrap';
import './Events.css';
import './Dapp.css';

export function Events({data}) {
    const [radioValue, setRadioValue] = useState('recruits');
    const radios = [
        { name: 'Recruits', value: 'recruits' },
        { name: 'Tribulations', value: 'tribulations' },
        { name: 'Valhalla', value: 'valhallas' }
    ];
    const showData = data[radioValue];
    return (
        <div className='MainBodyElement' style={{width:'80%', padding:'1%', overflowY:'scroll'}}>
      <h4>Events</h4>
        <ButtonGroup className='EventsControlButton' toggle style={{width:'50%', margin:'0 auto', padding:'0'}}>
        {radios.map((radio, idx) => (
          <ToggleButton
            className="EventsChoiceToggle"
            key={idx}
            type="radio"
            variant="secondary"
            name="radio"
            value={radio.value}
            checked={radioValue === radio.value}
            onChange={(e) => setRadioValue(e.currentTarget.value)}
          >
            {radio.name}
          </ToggleButton>
        ))}
        </ButtonGroup>
        <br />
        <BootstrapTable classes="EventsTable" keyField='eventKey' data={ showData.rows } columns={ showData.columns }/>
        </div>
    );
}
