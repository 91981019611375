function minMaxRGB(val){
    return Math.min(Math.max(0, val), 255);
}

export function get_rgb(rgb, adj=0){
    const r = (rgb>>16)&0x0ff;
    const g = (rgb>>8)&0x0ff;
    const b = (rgb)&0x0ff;
    const ret = [minMaxRGB(r+adj), minMaxRGB(g+adj), minMaxRGB(b+adj)]
    return ret;
}

export function draw_villain(ctx, villain, pixelData, factor=5){
    for (let j = 0;j< villain['attrs'].length; j++){
        const attr = villain['attrs'][j];
        const numBlessed = villain['numBlessed'];
        const numPunished = villain['numPunished'];
        const adj = (numBlessed - numPunished) * 16;
        let pixels = pixelData[attr.name].pixels;
        for (var z = 0;z < pixels.length; z++)
        {
            if(!pixelData[attr.name].useMap[z])
                continue;
            var y = parseInt(z/16) * factor;
            var x = z % 16 * factor;
            let rgb = get_rgb(pixels[z], adj);
            ctx.fillStyle = `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
            ctx.fillRect(x,y, factor, factor);
        }
    }


}

export function short_addr(addr, num=6){
    if(!addr)
        return "";
    return addr.slice(0, num) + "..." + addr.slice(-4);
}

export function update_cache(attrName, val, prevState){
    const statusData = {...prevState.statusData};
    statusData[attrName] = val;
    localStorage.setItem(attrName, val);
    return {statusData};
}

export function isValidURL(string) {
  var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);
  return (res !== null)
};
