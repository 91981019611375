import React, {useState} from "react";
import './Dapp.css';

export function Login({ loginFunc }) {
  const [password, setPassword] = useState('')
  return (
    <div className="Login" style={{fontFamily:'PressStart2P', fontSize:'.8em'}}>
      <h4>Login BitVillains Beta</h4>
      <form
        onSubmit={(event) => {
          loginFunc(password);
        }}
      >
        <div className="form-group" style={{width:'50%'}}>
          <input className="form-control" type="text" name="password" required
          onChange={(event)=>{setPassword(event.target.value)}}
          />
          <br />
          <input style={{background:'#008080', borderColor:'black'}} className="btn btn-primary"
            value="Login" 
            type="button"
            onClick={(event) => {
              loginFunc(password);
            }}
          />
        </div>
      </form>
    </div>
  );
}
