import React from "react";

import {Card, CardDeck, Tooltip, OverlayTrigger} from 'react-bootstrap';
import {draw_villain} from "../helper.js";
import { Link } from "react-router-dom";
import { ethers } from "ethers";

const MAX_VILLAINS = 100;
const RADIOS = [
    { name: 'All', value: 'all' },
    { name: 'Genesis', value: 'genesis' },
    { name: 'At Large', value: 'atlarge' },
    { name: 'Valhalla', value: 'valhalla' }
];
const HELPER_TEXTS = {
    'genesis': "Genesis BV's were created at contract initiation and are not eligble for Valhalla rewards",
    'atlarge': "At Large BV's are created but not currently in Valhalla",
    'valhalla':"Valhalla BV's have been sent permanently to Valhalla",
    'all': ""
}
export class Villains extends React.Component {
  constructor(props) {
    super(props);

    this.initialState = {
        canvasRendered : false,
        showFilter:'all'
    }

    this.state = this.initialState;
    for (var i = 0;i < MAX_VILLAINS; i++) {
        this[`ref_${i}`] = React.createRef();
    }
    this.onValueChange=this.onValueChange.bind(this);
  }

  componentDidUpdate() {
    this.drawCanvas(true);
  }

  componentDidMount() {
    this.drawCanvas();
  }
  onValueChange(event) {
    console.log(event.target.value);
    this.setState({
      showFilter: event.target.value
    });
  }

  drawCanvas(force=false){
    if (!this.props.pixelData || (!force && this.state.canvasRendered))
        return;
    for (let i = 0;i < this.props.villains.length; i++) {
        const canvas = this[`ref_${i}`].current;
        if (!!canvas && this.props.pixelData)
        {
            const ctx = canvas.getContext("2d");
            const villain = this.props.villains[i];
            // assume square canvas!
            draw_villain(ctx, villain, this.props.pixelData, ctx.canvas.width/16);
            if(!this.state.canvasRendered)
                this.setState({canvasRendered:true});
        }
    }
  }

  renderVillainGrid() {
    /*const renderTooltip = props => (
        <Tooltip {...props}>Genesis BV</Tooltip>
    );*/
    const els = [];
    for (let i = 0 ;i < this.props.villains.length; i++) {
        let style = {minWidth: "10rem", maxWidth: "10rem"}
        style['backgroundColor'] = 'white';
        style['borderColor'] = '#008080';
        style['borderWidth'] = '2px';
        style['marginBottom'] = '1%';
        style['marginRight'] = '1%';
        const v = this.props.villains[i];
        if(!this.props.isPlunder){
            let vCat = 'atlarge';
            let titleDiv = <div> BV {v.id} </div>;
            if (v.owner === 'valhalla'){
                vCat = 'valhalla';
                style['backgroundColor'] = 'grey';
                // only show tooltip if filtering display
                if (this.state.showFilter=== 'all')
                    titleDiv = <OverlayTrigger placement="top" overlay={<Tooltip>In Valhalla< /Tooltip>}>{titleDiv}</OverlayTrigger>;
            }
            else if (v.plunderAddr === ethers.constants.AddressZero){
                vCat = 'genesis';
                style['backgroundColor'] = '#800000';
                style['borderColor'] = 'black';
                // only show tooltip if filtering display
                if (this.state.showFilter=== 'all')
                    titleDiv = <OverlayTrigger placement="top" overlay={<Tooltip>Genesis BV< /Tooltip>}>{titleDiv}</OverlayTrigger>;
            }
            if (this.state.showFilter!=='all' && this.state.showFilter!==vCat){
                style['display'] = 'none';
            }
            els.push(
                    <Card key={i} style={style}>
                    <Card.Body>
                    <Card.Title>
                    {titleDiv}
                    </Card.Title>
                    <canvas width='128px' height='128px' style={{width:'100%'}} ref={this[`ref_${i}`]}/>
                    <Card.Text>
                    </Card.Text>
                    <Link style={{textDecoration:'none', color:'black'}} to={'/villain/' + v.id}>Details</Link>
                    </Card.Body>
                    </Card>);
        }
        else{
            if (ethers.constants.AddressZero === v.plunderAddr)
                continue;
            style['minWidth'] = '16rem';
            style['maxWidth'] = '20rem';
            els.push(
                    <Card key={i} style={style}>
                    <Card.Body>
                    <Card.Title>
                        Plunder {v.id}
                    </Card.Title>
                    <Card.Text>
                    {v.metadata ?
                    <img alt="NFT metadata" style={{border:'4px solid #008080', maxWidth:'30%'}} src={v.metadata} />
                    :
                    <div>Plunder Address: {v.plunderAddr}<br /><br />
                    Plunder Id: {v.plunderId}<br /></div>}
                    </Card.Text>
                    </Card.Body>
                    </Card>);
        }
    }
        return (
            <div>
            <br />
            <CardDeck style={{display:'flex', flexWrap:'wrap'}}>
            {els}
            </CardDeck>
            </div>
        );
    }

  render() {
    const onValueChange = this.onValueChange;
    const showFilter = this.state.showFilter;
    const helperText = HELPER_TEXTS[showFilter];
    return (
       <div style={{width:'80%', margin: '0 auto'}}>
       {(!this.props.isPlunder && !this.props.isUserVillains) &&
       <div style={{borderRadius:'5px', padding:'2px', background:'#D2D2D2', display:'inline-block'}}>
       <h4>Explore BitVillains</h4>
        <form>
        {RADIOS.map(function(x, i){
            return (<div key={i}><label><input checked={showFilter === x['value']} type='radio' value={x['value']} name='showFilter' onChange={onValueChange}></input> {x['name']}</label></div>);
        })}
        </form>
        <div style={{fontSize:'9px', float:'right'}}>{helperText}</div>
        </div>
        }
        {this.renderVillainGrid()}
        </div>
    );
  }

}
