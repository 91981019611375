import React from "react";
import { Line } from 'react-chartjs-2';


export function BondingCurve({ awardItem, address, curves}) {
  return (
    <div style={{textAlign:'left', width:'75%', padding:"1%"}} className='MainBodyElement'>
      <h4>Bonding Curve</h4>
      <Line
            data={{
                labels: curves.recruit.map(x => x.numBVAlive),
                datasets: [
                    {
                        data: curves.recruit.map(x => x.rp),
                        label: "Recruitment Price (ETH)",
                        borderColor: "#008080",
                        fill:false
                    },
                    {
                        data: curves.valhalla.map(x => x.vp),
                        label: "Valhalla Price (ETH)",
                        borderColor: "#002080",
                        fill:false
                    }
                ]
            }}
            options={
                {animation:{duration:0}, 
                tooltips: {enabled: false}, 
                hover: {mode: null},
                plugins: {
                    title:{display:false, text:'hi'}
                },
                scales: {
                    y: {
                        title: {
                            display: true,
                            text: 'Price (ETH)'
                        }
                    },
                    x: {
                        title: {
                            display: true,
                            text: 'BitVillains At Large'
                        }
                    }
                }
            }}
      />
    </div>
  );
}
