
import React from "react";
import { Link, useLocation } from "react-router-dom";
import {Image, Tooltip, OverlayTrigger} from 'react-bootstrap';
import {short_addr} from "../helper.js";
export function MainMenu({address}) {
  const {pathname} = useLocation();
  const activeStyle={background:'#008080'};
  const normalStyle = {}
  const mainMenuBarData = [
        {text:'Home', link:'/index'},
        {text:'Recruitment', link:'/recruitment'},
        {text:'Helper NFT', link:'/helper'},
        {text:"Rogues' Gallery", link:'/bitVillains'},
        {text:'Plunder', link:'/plunder'}
  ];
  const intelMenuData = [
        {text:'Attribute Pool', link:'/attributePool'},
        {text:'Events', link:'/events'},
        {text:'BondingCurve', link:'/bondingCurve'},
        {text:'My BitVillains', link:'/userBitVillains'}
  ];
  let val = <div />;
  if(address){
    val = (<div>Connected Wallet: {short_addr(address)}</div>);
  }
  return (
      <div style={{display:'flex', alignItems:'center', justifyContent:'start', background:'rgba(0, 0, 0, 0.85)', padding:'4px'}}>
        <Image className="MainLogo" src={"/images/logo1.png"} style={{marginRight:'5%'}}/>
        <ul className="mainNavBar">
          {mainMenuBarData.map(function(x, i){
              return <li className='navEl' key={i} style={pathname===x['link'] ? activeStyle : normalStyle}><Link className={'a'} to={x['link']}>{x['text']}</Link></li>;
          })}
          <li className="mainDropDown">
          <div className="dropbtn">Intel</div>
          <div className="mainDropDown-content">
          {intelMenuData.map(function(x, i){
              return <Link className="dropDownItem" key={i} to={x['link']} style={pathname===x['link'] ? activeStyle : normalStyle}>{x['text']}</Link>
          })}
          </div>
          </li>
          <li className='walletEl'>
         <OverlayTrigger placement="bottom" overlay={<Tooltip >{address}< /Tooltip>}>{val}</OverlayTrigger>
          </li>
        </ul>
    </div>
  );
};
