import React, {useState} from "react";
import { ethers } from "ethers";
import {Link} from "react-router-dom";
import "./Dapp.css";
import {short_addr} from "../helper.js";
import {Tooltip, OverlayTrigger} from 'react-bootstrap';

export function Recruitment({ recruitMember, isApproved, getApproval, pricing, defaultValue, hasProvider, networkReady}) {
  const [approved, setApproved] = useState(false);
  const [state, setState] = useState({
      sAddr: defaultValue,
      sId: "0"
  });
  
  function inputChanged(event){
      const val = event.target.value
      setState({
        ...state,
        [event.target.name]: val
      });
      let sAddr = state.sAddr;
      let sId = state.sId;
      if(event.target.name === 'sAddr')
          sAddr = val;
      else if(event.target.name === 'sId')
          sId = val;
      checkApproval(sAddr, sId);
  }

  function checkApproval(sAddr, sId){
      if(ethers.utils.isAddress(sAddr) && sId !== ""){
          isApproved(sAddr, sId).then((result)=>{
            setApproved(result !== ethers.constants.AddressZero);
          });
      }

  }

  function recruitMemberClicked(event){
      if (state.sAddr && state.sId) {
        recruitMember(state.sAddr, state.sId).then((result)=>{
      });
      }
    }
  function getApprovalClicked(event){
      if (state.sAddr && state.sId) {
        getApproval(state.sAddr, state.sId).then((result)=>{
            checkApproval(state.sAddr, state.sId);
        });
      }
    }

  let rp = "0.5";
  if(pricing && pricing.recruit)
    rp = pricing.recruit;
  const recruitText = "Recruit Member for " + rp + " ETH with plunder " + (state.sId ? state.sId : '?') + " from "+short_addr(state.sAddr);
  const approvalText = "Get Approval for " + (state.sId ? state.sId : '?') + " from "+short_addr(state.sAddr);
  return (
    <div style={{textAlign:'left', width:'80%', padding:"1%"}} className="MainBodyElement">
      <h4>Recruit a New BitVillain</h4>
      <form
      >
        <p>
        An NFT plunder is required to recruit a new BitVillain. Even if a BitVillain is sent to Valhalla, the plunder will remain locked up in perpetuity.
        </p>
        <p>
         In the testnet environment, there is a <Link to="/helper">sample NFT contract </Link>used to generate a new sacrificial NFT for recruitment. Once minted successfully, it appears in the "Sacrificial NFT Mints" table with the corresponding owner and generated plunder id.
        <br />
        <br />
        The sample NFT contract (and like all ERC721 standard NFTs on ethereum) first requires an approval transaction to allow the BV contract to transfer the plunder id to itself. After the approval transaction is confirmed, the plunder can be used to recruit a new BitVillain.
        </p>
        <div className="form-group">
          <label>Plunder Contract address (pre-populated with sample NFT)</label>
          <input className="form-control" type="text" name="sAddr" required
            value={state.sAddr}
            onChange={inputChanged}
            disabled={true}
          />
        </div>
        <br />
        <div className="form-group">
          <label>Plunder Id</label>
          <input className="form-control" type="number" name="sId" required onChange={inputChanged} value={state.sId}/>
        </div>
        <br />
        <div>
        {approved ?
        <div className="form-group">
          <OverlayTrigger placement="top" overlay={<Tooltip >Plunder has already been approved< /Tooltip>}><div style={{opacity:'50%', background:'#008080', borderColor:'black'}} className="btn btn-primary">{approvalText}</div></OverlayTrigger>
        </div>
        :
        <div className="form-group">
          <input style={{background:'#008080', borderColor:'black', whiteSpace:'normal'}} className="btn btn-primary" type="button" value={approvalText} disabled={approved || (!hasProvider || !networkReady)}
             onClick={getApprovalClicked}/>
        </div>
        }
        <br />
        {approved ?
        <div className="form-group">
          <input style={{background:'#008080', borderColor:'black', whiteSpace:'normal'}} className="btn btn-primary" type="button" value={recruitText}
             disabled={!approved || (!hasProvider || !networkReady)}
             onClick={recruitMemberClicked}/>
        </div>
        :
        <div className="form-group">
          <OverlayTrigger placement="top" overlay={<Tooltip>Plunder needs approval before recruitment can occur< /Tooltip>}><div style={{opacity:'50%', background:'#008080', borderColor:'black'}} className="btn btn-primary">{recruitText}</div></OverlayTrigger>
        </div>
        }
        </div>
      </form>
    </div>
  );
}
