import React from "react";
import deploymentInput from "../deployment_input.json";
// We'll use ethers to interact with the Ethereum network and our contract
import {Card, CardDeck} from 'react-bootstrap';
import {get_rgb} from "../helper.js";
import './Dapp.css';

const ATTRIBUTE_CATEGORIES = Object.keys(deploymentInput);
let ATTRIBUTE_LIST;

export class AttributePage extends React.Component {
  constructor(props) {

    if(!ATTRIBUTE_LIST){
        ATTRIBUTE_LIST = [];
        for (const categoryName of ATTRIBUTE_CATEGORIES) {
            console.log(categoryName);
            for (const attr of deploymentInput[categoryName]) {
                let name = attr['name'];
                ATTRIBUTE_LIST.push(name);
            }
        }
    }
    super(props);

    this.initialState = {
        canvasRendered : false,
        attrFilter: 'all'
    }

    this.state = this.initialState;
    this.init();
  }

  init(){
    for (var attrName of ATTRIBUTE_LIST) {
        this[`ref_${attrName}`] = React.createRef();
    }

  }

  componentDidMount() {
    this.drawCanvas();
  }

  componentDidUpdate() {
    this.drawCanvas();
  }

  drawCanvas() {
    if (!this.props.pixelData || this.state.canvasRendered)
        return;
    for (var key of ATTRIBUTE_LIST) {
        const canvas = this[`ref_${key}`].current;
        if (!!canvas)
        {
            const ctx = canvas.getContext("2d");
            let xFactor = ctx.canvas.width/16;
            let yFactor = ctx.canvas.height/16;
            let pixels = this.props.pixelData[key]['pixels'];
            let invert = false;
            if(key.includes('White'))
                invert = true;
            for (var i = 0;i < pixels.length; i++)
            {
                var y = parseInt(i/16) * yFactor;
                var x = (i % 16) * xFactor;
                let draw = false;
                if(this.props.pixelData[key]['useMap'][i])
                    draw = true;
                if(draw){
                    let rgb = get_rgb(pixels[i]);
                    ctx.fillStyle = `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
                    ctx.fillRect(x,y, xFactor, yFactor);
                }
                else if(invert){
                    ctx.fillStyle = `rgb(0, 0, 0)`;
                    ctx.fillRect(x,y, xFactor, yFactor);
                }
            }
            this.setState({canvasRendered:true});
        }
    }
  }

  renderAttributeGrid() {
    if (!this.props.pixelData)
        return;

    const els = [];
    for (var key of ATTRIBUTE_LIST) {
        let remaining = 'loading';
        let initial = 'loading';
       var category;
       const style ={textAlign:'center', minWidth: "10rem", maxWidth: "10rem", borderColor:'#008080', marginBottom: '1%', marginRight:'1%'};
       if (this.props.attrData){
            let attrData = this.props.attrData[key];
            if (attrData){
                remaining = attrData['remainingGenePool'];
                initial = attrData['initialGenePool'];
                category = attrData['category'];
            }
        }
        if (this.state.attrFilter!=='all' && this.state.attrFilter!==category){
            style['display'] = 'none';
        }
    els.push(<Card key={key} style={style}><Card.Body><Card.Title style={{fontSize:'.9em'}}>{key.replace(/([A-Z])/g, ' $1')}</Card.Title><canvas width='128px' height='128px' style={{margin:'0', padding:'0', width:'100%'}} ref={this[`ref_${key}`]}/><Card.Text>{remaining}/{initial}</Card.Text></Card.Body></Card>);
    }
        return (
            <CardDeck style={{display:'flex', flexWrap:'wrap', width:'100%'}}>
            {els}
            </CardDeck>
        );
    }

  onChangeFilter(e){
    this.setState({attrFilter:e.target.value})
    this.drawCanvas();
  }


  render() {
    return (
       <div style={{width:'75%', margin: 'auto', padding:'0.5%'}}>
       <div>
       <div style={{borderRadius:'5px', padding:'2px', background:'#D2D2D2', display:'inline-block'}}>
       <h4>Attribute Pool</h4>
        Attribute Filter: {" "}
       <select onChange={this.onChangeFilter.bind(this)} style={{margin: '0 auto'}}>
        {['all'].concat(ATTRIBUTE_CATEGORIES).map(num => <option value={num} key={num}>{num}</option>)}
        </select>
        </div>
        </div>
        <br />
        {this.renderAttributeGrid()}
        </div>
    );
  }
}

export default AttributePage;
