import React, {useState, useRef, useEffect} from "react";
import {useParams} from "react-router-dom";
import {draw_villain, short_addr} from "../helper.js";
import BootstrapTable from 'react-bootstrap-table-next';
import './Dapp.css';

const IMG_SIZE = 384;
const ATTR_TABLE_COLUMNS = [
    {'dataField': 'cat', 'text': 'Category'},
    {'dataField': 's', 'text': 'Attribute'}
];
export function Villain({villainData, pixelData, userAddress, tribulation, valhalla, pricing, categories}) {
  let {vId} = useParams();
  const [canvasRendered, setCanvasRendered] = useState(false);
  const canvasRef = useRef();
  const villain = villainData[vId];

  function drawCanvas(){
    const canvas = canvasRef.current;
    if(canvas && !canvasRendered && pixelData){
        draw_villain(canvas.getContext('2d'), villain, pixelData, canvas.getContext("2d").canvas.width/16);
        setCanvasRendered(true);
    }
  }

  useEffect(() =>{
    drawCanvas();
  });
  const mainStyle ={width:IMG_SIZE*1.17, textAlign:'center'};
  if(!villain){
    return(<div style={mainStyle} className="MainBodyElement">
      <h4>Villain {vId} not found!</h4>
    </div>);
  }
  const data = [];
  for (var i=0; i<villain['attrs'].length; i++){
    const attr = villain['attrs'][i];
    let cat = categories[i];
    cat = cat.charAt(0).toUpperCase() + cat.slice(1);
    let s = "";
    for (const w of attr['name'].split(/(?=[A-Z])/))
        s+=w+" ";
    data.push({cat, s});
  }
  return (
    <div style={mainStyle} className="MainBodyElement">
      <h4>Villain {vId}</h4>
      <div><canvas height={IMG_SIZE} width={IMG_SIZE} ref={canvasRef} /></div>
      <br />
      <div>Owner: {villain['owner'] === 'valhalla' ? 'valhalla' : short_addr(villain['owner'], 8)}</div>
      <div>Plunder Contract Addr: {short_addr(villain['plunderAddr'])}</div>
      <div>Plunder Id: {villain['plunderId']}</div>
      <BootstrapTable keyField='cat' classes="VillainDetailsTable" data={data} columns={ATTR_TABLE_COLUMNS} style={{tableLayout:'auto', color:'blue'}}/>
      <div>Times Blessed: {villain['numBlessed']}</div>
      <div>Times Punished: {villain['numPunished']}</div>
      <br />
      {villain['owner'] === userAddress &&
          <div>
          <div>
          <input className="btn btn-primary" type="button" value={"Tribulation for "+pricing.tribulation+" ETH"}
             onClick={()=>tribulation(vId)}/>
        </div>
          <br />
          <div>
          <input className="btn btn-primary" type="button" value={"Valhalla for "+pricing.valhalla+" ETH"}
             onClick={()=>valhalla(vId, 0)}/>
        </div>
        <br />
        </div>
      }
    </div>
  );
}
