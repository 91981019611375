import React, {useState} from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import {isValidURL} from "../helper.js";
import {Tooltip, OverlayTrigger} from 'react-bootstrap';

export function HelperNFT({ awardItem, address, data, hasProvider, networkReady}) {
  const [state, setState] = useState({
      imgSource: "./images/sampleNFT.png",
      imgSourceValid: true
  });
  const rowStyle = (row, rowIndex) => {
      let style = {fontWeight:'normal'};
      if (row["to"] === address){
          style['fontWeight'] = 'bold';
      }
      return style;
  };

  function inputChanged(event){
      const val = event.target.value
      let valid = state.imgSourceValid;
      if(event.target.name === 'imgSource'){
        if(val === "" || isValidURL(val))
          valid = true;
        else
          valid = false;
      }
      setState({
        ...state,
        [event.target.name]: val,
        imgSourceValid: valid
      });
  }

  let toolTipDiv;
  const imgSrcStyle = {fontSize:'.7em'};
  if(state.imgSourceValid)
    toolTipDiv=<div>Change URL for NFT image (can be blank)</div>;
  else{
    toolTipDiv=<div>Invalid URL</div>;
    imgSrcStyle['background'] = 'pink';
  }
  return (
    <div style={{textAlign:'left', width:'50%', padding:"1%"}} className='MainBodyElement'>
      <h4>Generate a Sample Plunder NFT</h4>
          <label>Image metadata source: </label>
          <OverlayTrigger placement="top" overlay={<Tooltip>{toolTipDiv}< /Tooltip>}>
          <input type="text" name="imgSource" required
            value={state.imgSource}
            onChange={inputChanged}
            style={imgSrcStyle}
          /></OverlayTrigger>
          <br />
          <div style={{display:'inline-block'}}>
          {(state.imgSourceValid && state.imgSource)&& 
          <div style={{marginTop:'1%', display: 'inline-block'}} >
          <img alt="NFT metadata" style={{border:'4px solid #008080', maxWidth:'30%'}} src={state.imgSource} />
          </div>}
          <input style={{display: 'inline-block', background:'#008080', borderColor:'black'}} className="btn btn-primary"
            value="Mint NFT" 
            type="button"
            onClick={(event) => {
              if (address) {
                awardItem(address, state.imgSource);
              }
            }}
            disabled={(!hasProvider || !networkReady) || !state.imgSourceValid}
          />
          </div>
      <br /><br />
      <h4>Sample Plunder NFT Mints</h4>
        <BootstrapTable classes="EventsTable" keyField='tokenId' data={ data.rows } columns={ data.columns } style={{tableLayout:'auto', color:'blue'}} rowStyle={rowStyle}/>
    </div>
  );
}
