import React from "react";
import './Dapp.css';

export function WalletStatus({statusData, hasProvider, onCorrectNetwork, networkReady, connectWallet }) {
  const blockNumber = statusData.currentBlock || 'loading';
  const cachedBlock = statusData.cachedBlock || 'loading';
  const mainStyle = {overflow:'hidden', padding:'5px', fontSize:'8px', background:'yellow', color:'black', width:'33%', margin:'0 auto'};
  if (statusData.currentBlock && hasProvider && networkReady){
      if(!statusData.cachedBlock)
          mainStyle['background'] = 'red';
      else{
          const blockDiff = blockNumber - cachedBlock;
          if (blockDiff > 20){
              mainStyle['background'] = 'red';
          }
          else if (blockDiff > 10){
              mainStyle['background'] = 'pink';
          }
          else if (blockDiff > 1){
              mainStyle['background'] = 'yellow';
          }
          else{
              mainStyle['background'] = '#008080';
              mainStyle['color'] = 'white';
          }
      }
  }
  let mainDiv =(
  <div>
        <div style={{float:'left'}}>
        Latest Block: {blockNumber}
        </div>
        <div style={{float:'right'}}>
        Displayed Block: {cachedBlock}
        </div>
  </div>);
  if(!hasProvider){
      mainDiv = <div>No Web3 provider detected, blockchain data cannot be displayed.<br />Please install <a href="http://metamask.io" target="_blank" rel="noopener noreferrer"> MetaMask</a></div>;
      mainStyle['fontSize'] = '20px';
      mainStyle['background'] = 'red';
      mainStyle['width'] = '80%';
      mainStyle['textAlign'] = 'center';
  }
  else if(!onCorrectNetwork){
      mainDiv = <div>Connected to incorrect network, blockchain data cannot be displayed.<br />Please connect to correct network (Ropsten) in MetaMask</div>;
      mainStyle['fontSize'] = '18px';
      mainStyle['background'] = 'red';
      mainStyle['width'] = '80%';
      mainStyle['textAlign'] = 'center';
  }
  else if(!networkReady){
      mainDiv = <div><p>Connected to correct network but awaiting Web3 connection, please connect in MetaMask</p>
        <button onClick={connectWallet} type="button">Connect</button>
          </div>;
      mainStyle['fontSize'] = '12px';
      mainStyle['background'] = 'yellow';
      mainStyle['width'] = '80%';
      mainStyle['textAlign'] = 'center';
  }

  return (
    <div style={mainStyle}>
      {mainDiv}
    </div>
  );
}
